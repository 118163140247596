
.comparison_main_outer {
  width: 100% !important;
  height: auto;
  background: #EBFBFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EBFBFF;
  padding-bottom: 3vw;
}

.comparison_inner_main {
  width: 72% !important;
  background: #EBFBFF;
}

.comparison_heading {
  height: 11vw;
  color: #062b63;
  text-align: center;
  font-family: sans-serif;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3vw; /* 125% */
  background: #EBFBFF;
}

.img_of_logo {
  height: 7vw;
  width: 7vw;
  aspect-ratio: 1/1;
  background: #EBFBFF;
}

.img_logo_div {
  height: 11vw;
  display: flex;
  align-items: end;
  justify-content: center;
  background: #EBFBFF;
}

.comparison_left {
  height: 4vw;
  background: #EBFBFF;
  color: #434343;
  text-align: center;
  font-family: sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
  display: flex;
  align-items: center;
  justify-content: center;
}

.comparison_right {
    background: #EBFBFF;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vw;
  color: #434343;
  text-align: center;
  font-family: sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.capsule_div {
  height: 4vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EBFBFF;
}

.capsule_img {
  width: 27vw;
}


@media only screen and (max-width: 991px) {
  .comparison_main_outer {
    margin-top: 3vw;
  }
}

@media only screen and (max-width: 767px) {
  .comparison_inner_main {
    display: none;
  }

  .complete_pic_div{
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .complete_pic{
    width: 100%;
    
  }

}

@media only screen and (min-width: 767px) {
  .complete_pic_div {
    display: none;
  }
}


