.outer_colored_div{
    width: 100%;
height: 18vw;
background-color: #062B63;

}

.inner_colored_div{
    display: flex;
    align-items: center;
    /* gap: 0vw; */
    margin-left: 15%;
}

.consultation_div{
    width: 70%;
    display: flex;
    align-items:flex-start;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    padding-top: 3vw;
    width: 60%;

}

.consultation_text{
    color: #FFF;
text-align: center;
font-family: sans-serif;
font-size: 2.3vw;
font-style: normal;
font-weight: 700;
line-height: 2vw; /* 125% */
}

.below_consultation_text{
    color: #FFF;
font-family: sans-serif;
font-size: 1.6vw;
font-style: normal;
font-weight: 500;
line-height: 3vw; /* 156.25% */
text-align: left;
}

.coloured_buttonn{
    margin-top: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 385px;
    border-radius: 0.5vw;
height: 4vw;
width: 18vw;
color: #0A2E65;
font-family: sans-serif;
font-size: 1.2vw;
font-style: normal;
font-weight: 700;
line-height: normal;
background-color: #00D1FF;
box-shadow: 0px 0.2vw #fff;
}

.phone_no_div{
    margin-top: 6vw;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.light_phone_img{
    width: 4vw;
    height: 4vw;
    aspect-ratio: 1/1;
}

.contact_no{
    margin-top: 1vw;
color: #00D1FF;
    color: #00D1FF;
text-align: center;
font-family: sans-serif;
font-size: 3vw;
font-style: normal;
font-weight: 700;
line-height: normal;
}