.main_coloured_question_div {
  width: 100%;
  background: #062b63;
  padding-bottom: 3vw;
}

.div_after_main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.questions_heading {
  margin-top: 3vw;
  color: #fff;
  text-align: center;
  font-family: sans-serif;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 125% */
}

.cards_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  line-height: 5vw !important;
}

.left_column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5vw;
  height: 100% !important;
  padding-right: 1vw !important;
}


.right_column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.all_cards {
  background-color: #fff;
  width: 32vw;
  border-radius: 0.5vw;
  margin-bottom: 1vw;
  color: #00d1ff;
  text-align: center;
  font-family: sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 178.571% */
  padding: 1vw 1vw;
}

.card_headings{
    line-height: 1.5vw;
    margin-bottom: 0.7vw;
    font-size: 1.2vw;
    /* margin: 0px 0px !important; */
}

.paragraphss {
  color: #2e2e2e;
  text-align: center;
  font-family: sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3vw; /* 133.333% */
  padding: 0vw 1.3vw;

}


.card_one{
    height: 15vw !important;
}

.card_two{
    height: 11vw !important;
}

.card_three{
    height: 13vw !important;
}



.card_four{
    height: 8vw !important;
}

.card_five{
    height: 12vw !important;
}

.card_six{
    height: 12vw !important;
}

.card_seven{
    height: 6vw !important;
}