.main_testimonial_div{
    width: 100% !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    margin-top: 3vw;
    margin-bottom: 3vw !important;


}

.slick-dots li button:before {
    background-image: url(./../../Assets/lightb.png);
    box-shadow: none;
    text-shadow: none;
    color: transparent !important;
    background-size: 0.8vw 0.8vw;
    background-repeat: no-repeat;

}

.slick-slider{
    width: 98% !important;
}
.ttt{
    margin-left: 24vw;
}

.slick-dots{
    margin: 2vw 0px !important;
    width: 100% !important;
}

.testimonial_heading{
    color: #062B63;
text-align: center;
font-family: sans-serif;
font-size: 2.5vw;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 125% */
}

.testiminoal_img_div{
    margin-top: 2vw;
}

.testimonial_img{
    width: 50vw;
}

.dots_img_div{
    margin-top: 1vw;
    
}

.dots_img{
    width: 8vw;
}


@media only screen and (max-width: 991px){
    .testiminoal_img_div{
        margin-top: 0.5vw;
    }

    /* .slick-dots{
        margin: 4vw 0px !important;
        width: 100% !important;
    } */
}


@media only screen and (max-width: 500px){
    .testiminoal_img_div{
        margin-top: 0.5vw;
    }

    .slick-dots{
        margin-bottom:3vw !important;
        width: 100% !important;
    }
}