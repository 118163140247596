.main_form_america {
  /* margin: 3vw 15vw; */
  margin-top: 3vw !important;
  margin-left: 15vw;
  margin-right: 10vw;
  display: flex;
  flex-direction: row;
  height: 100% !important;
  

}

.america_heading{
    color: #062B63;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
font-family: sans-serif;
font-size: 3vw;
font-style: normal;
font-weight: 700;
line-height: 3.7vw; 
}

.below_america_heading{
    color: #00D1FF;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
font-family: sans-serif;
font-size: 1.2vw;
font-style: normal;
font-weight: 500;
line-height: 40px; /* 125% */
}

.left_div{
    width: 35% !important;
    
}

.right_div{
    width: 65% !important;
    position: relative;
    
}

.america_img_div{
    background-image: url(./../../../Assets/flag.png) ;
    width: 1000px !important;
    height: 100% !important;
    background-size: 100% 100%;
    background-repeat: no-repeat !important;
    
}

.stars_text_div{
    display: flex;
   margin-top: 1vw;
    justify-content: start;
    flex-direction: column;
    gap: 0.5vw;
}


.inner_row_flex{
    display: flex;
    gap: 1vw;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
}

.star_img{
    width: 1.6vw;
    height: 1.6vw;
    aspect-ratio: 1/1;
}

.star_text{
    font-size: 1vw;
    font-family: sans-serif;
    font-weight: 400;
}

.side_form_background {
margin-left: 5vw !important;
  border-radius: 0.7vw;
  background: #062b63;
  width: 18vw;
   height: 500px; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin-top: 50px !important; */
  gap: 1rem;
}

.form_heading {
  color: #f8f9fb;
  font-family: "Poppins";
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: center;
}

.my_form_labels {
  color: #f8f9fb;
  font-family: Poppins;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.my_form_inputs {
  width: 14vw;
  height: 2vw;
  margin-bottom: 0.5vw;
  outline: none;
  border-radius: 7px;
}

.my_form_button {
  margin-top: 1vw !important;
  width: 14vw;
  height: 2.7vw;
  border-radius: 10px;
  background: #00d1ff;
  box-shadow: 0px 3px #ffffff;
  border: none;
  color: #0a2e65;
  font-family: sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn_class_center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@media only screen and (min-width: 1900px){
    .side_form_background {
        border-radius: 0.7vw;
        background: #062b63;
        width: 18vw;
         height: 30vw; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /* margin-top: 50px !important; */
        gap: 1rem;
      }
}

@media only screen and (max-width: 1440px){
    
.side_form_background {
    border-radius: 0.7vw;
    background: #062b63;
    width: 18vw;
     height: 32vw !important; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 50px !important; */
    gap: 1rem;
  }
}

@media only screen and (max-width: 1280px){
    .side_form_background {
        border-radius: 0.7vw;
        background: #062b63;
        width: 18vw;
         height: 338px; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /* margin-top: 50px !important; */
        gap: 1rem;
      }
}
@media only screen and (max-width: 1024px){
    .side_form_background {
        border-radius: 0.7vw;
        background: #062b63;
        width: 18vw;
         height: 35vw !important; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /* margin-top: 50px !important; */
        gap: 1rem;
      }
}


@media only screen and (max-width: 991px) {

.right_div{
margin-top: 3vw;
}
    
.america_heading{
    color: #062B63;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
font-family: sans-serif;
font-size: 5vw;
font-style: normal;
font-weight: 700;
line-height: 5.5vw; 
}

.below_america_heading{
    color: #00D1FF;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
font-family: sans-serif;
font-size: 1.7vw;
font-style: normal;
font-weight: 500;
line-height: 2vw; /* 125% */
}

.stars_text_div{
    display: flex;
   margin-top: 1vw;
    justify-content: start;
    flex-direction: column;
    gap: 0vw;
}

.inner_row_flex{
    display: flex;
    gap: 1vw;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
}

.star_img{
    width: 3vw;
    height: 3vw;
    aspect-ratio: 1/1;
}

.star_text{
    font-size: 2vw;
    font-family: sans-serif;
    font-weight: 400;
}

  .main_form_america {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 3vw -2vw !important;

  }

  .side_form_background {
    margin-left: -5vw !important;
    

    border-radius: 0.7vw;
    background: #062b63;
    width:  42vw !important;
    height: 65vw !important;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0px !important;
    gap: 1rem;
    padding: 0px !important;
    
  }

  .form_heading {
    color: #f8f9fb;
    font-family: "Poppins";
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    
  }

  .my_form_labels {
    color: #f8f9fb;
    font-family: "Poppins";
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
  }

  .my_form_inputs {
    width: 32vw;
    height: 5vw;
    margin-bottom: 1vw !important;
    outline: none;
    border-radius: 7px;
  }

  .my_form_button {
    margin-top: 1vw !important;
    width: 32vw;
    height: 6.5vw;
    border-radius: 1vw;
    background: #00d1ff;
    box-shadow: 0px 3px #ffffff;
    border: none;
    color: #0a2e65;
    font-family: sans-serif;
    font-size: 2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

@media only screen and (max-width: 500px){
    .stars_text_div{
        display: flex;
       margin-top: 1vw;
        justify-content: start;
        flex-direction: column;
        gap: 0vw !important;
    }
    .side_form_background {
        height: 80vw !important;
    
        gap: 0rem !important;
    }

    .my_form_inputs {
        border-radius: 4px;
    }
}


@media only screen and (max-width: 600px){
 
    .side_form_background {
        height: 380px !important;
        gap: 0rem !important;
    }


}

@media only screen and (max-width: 360px){
    .side_form_background {
        height: 100vw !important;
        gap: 0rem !important;
    }


}