@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.steps_pic_main_div {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;
  margin: 0vw 14vw;
}

.satisfy_left {
  padding-top: 4vw;
  width: 50%;
  margin-bottom: 2vw !important;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.house_img {
  width: 36vw;
  height: 28vw;
  margin-left: 2vw;
  margin-top: 2vw;
}

.easy_heading {
  color: #062b63;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  font-family: sans-serif;
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1vw; /* 208.333% */
}

.satisfy_right {
  width: 50%;
  height: 33vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content:first baseline; */
  margin-top: 4vw;
}

.icons_and_text {
  display: flex !important;
  flex-direction: row !important;
  padding: 0.7vw !important;
  gap: 1vw;
  border: 1px solid black;
  border-radius: 0.5vw;
}

.three_icons {
  width: 5.5vw;
  height: 5.5vw;
  aspect-ratio: 1/1;
  margin-top: 1vw;
}

.icons_headings {
  color: #2e2e2e;
  font-family: sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 156.25% */
}

.below_icons_headings {
  color: #2e2e2e;
  font-family: sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 130% */
}

@media only screen and (max-width: 767px) {
  .satisfy_main {
    display: flex;
    flex-direction: column;
    margin-left: 0px !important;
    margin-right: 0px !important;
    align-items: center;
    justify-content: center;
  }

  .icons_headings {
    font-size: 1.5vw;
    line-height:3vw !important;
  }

  .below_icons_headings {
    font-size: 1.3vw;
    line-height: 1.5vw;
  }

  .three_icons {
    width: 8.5vw;
    height: 8.5vw;
    aspect-ratio: 1/1;
    /* margin-top: 2.4vw; */
  }

  .icons_and_text {
    padding: 1vw !important;
    margin-top: 2vw !important;
    
    gap: 1vw;
  }
}

@media only screen and (max-width: 2700px) {
  .icons_headings {
    color: #2e2e2e;
    font-family: sans-serif;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5vw; /* 156.25% */
  }

  .below_icons_headings {
    color: #2e2e2e;
    font-family: sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4vw; /* 130% */
    padding-top: 1vw;
  }
}

@media only screen and (max-width: 991px) {
  .steps_pic_main_div {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0vw 0vw;
    /* margin-left: 10vw; */
  }

  .satisfy_right {
    width: 100%;
    height: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-top: 4vw; */
  }

  .satisfy_right {
    width: 100% !important;
    height: 33vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* justify-content:first baseline; */
    margin-top: 4vw;
  }
  .house_img {
    width: 50vw;
    height: 35vw;
    margin-left: 2vw;
  }
}
